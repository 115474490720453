
.order_detail_wrap {
  width: 80%;
  margin: 10px auto;
  min-width: 900px;

  .order_step_wrap {
    background-color: #fafafa;
    padding: 20px;
  }

  .order_info {
    margin-top: 20px;
    border: 1px solid #E8E8E8;
    padding: 18px 24px;
  }

  .detail {
    width: 100%;
    height: max-content;
    padding: 20px 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.65);
    border-bottom: 1px dashed #E8E8E8;

    .name {
      flex: 1;
      color: rgba(0, 0, 0, 0.45);

      div:first-of-type {
        color: rgba(0, 0, 0, 0.65);
      }
    }

    .text {
      width: 120px;
      text-align: center;
    }

    .picture {
      width: 60px;
      height: 60px;
      margin: 0 18px 0 24px;
    }
  }
}



@primary-color: #1890ff;