.coupon_body_wrap{
  height: 100%;
  width: 100%;
  background: #F0F2F5;
  padding:24px;
  .container{
    background-color: #FFFFFF;
    padding: 10px;
    .table_wrap{
      padding: 0 10px;
      td{
        min-width: 100px;
      }

    }
  }

}

@primary-color: #1890ff;