.quantity_statistics_container {
  width: 100%;
  height: 400px;
  background-color: #FFFFFF;
  border-radius: 2px;

  .tab_line {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E8E8E8;
    height: 50px;
    justify-content: space-between;

    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: 0;
    }
  }

  .radio_line {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    height: 50px;
    display: flex;
    align-items: center;
  }

  .chart_container {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    height: 260px;
    margin-top: 20px;
  }
}

@primary-color: #1890ff;