.specification_add_wrap{
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  padding: 20px;
  .container{
    background-color: #ffffff;
    padding-top: 80px;
    height: 100%;
    padding-bottom: 30px;
    .table_wrap{
      width: 50%;
      height: 100%;
      min-width: 800px;
      margin: 0 auto;
      position: relative;

      .batch_wrap{
        margin-top: 10px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
      }
      .submit_wrap{
        border-top: 1px dashed #e9e9e9;
        padding-top: 10px;
        width: 100%;
        position: absolute;
        bottom: 10px;
        display: flex;
        justify-content: flex-end;
      }


    }
  }
}

@primary-color: #1890ff;