.ant-tabs-nav{
  margin: 0!important;
}
.list_filter{
  display: flex;
  height: 50px;
  padding: 17px 0 0 40px;
  background-color: #FFFFFF;
  .filter{
    margin-right: 64px;
    font-size: 14px;
    cursor: pointer;
    padding-bottom: 13px;
  }
  .active{
    border-bottom: 1px solid #1890FF;
    color:#1890FF
  }
}

@primary-color: #1890ff;