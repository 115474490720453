.watermark_wrap{
  width: 100%;
  height: 100%;
  padding: 12px;
  .body{
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    padding:40px 0 ;
    .wrap{
      border: 1px solid #eeeeee;
      box-shadow: #eae5e5 1px 1px 1px;
      width: 60%;
      height: 100%;
      margin: 0 auto;
      padding-top: 100px;
      padding-left: 10%;
      .tips{
        font-size: 14px;
        max-width: 180px;
        color: #979595;
      }
      .borderBottom{
        border-bottom:1px dashed #eeeeee;
        margin-top: 6px;
      }

    }
  }
}

@primary-color: #1890ff;