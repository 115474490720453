.assort_container {
  width: 800px;
  margin: 0 auto;
  height: 100%;

  .source_list {
    width: 100%;
    height: max-content;
    box-shadow: 0 0 6px rgba(0, 0, 0, .1);
    .source_list_item{
      width: 100%;
      height: max-content;
      box-sizing: border-box;
      padding: 24px;
    }
  }
}

@primary-color: #1890ff;