@import '~antd/dist/antd.less';

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: rgba(0, 0, 0, .1);
}

::-webkit-scrollbar-track {
  background: hsla(0, 0%, 100%, .1);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

* {
  word-break: break-all;
}

.flex_menu_item {
  display: flex;
  align-items: center;
}

.ant-layout-header {
  width: 100%;
  height: 48px;
  background: #FFFFFF;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.12);
  opacity: 1;
}

.ant-table-tbody {
  color: rgba(0, 0, 0, 0.65);;
}

.flex_center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex_1 {
  flex: 1;
}

.content_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main_wrap {
  height: 100%;
  width: 100%;
  min-width: 900px;
  background: #F0F2F5;
  padding: 12px 12px 0 12px;
  box-sizing: border-box;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .main_container {
    width: 100%;
    background-color: #FFFFFF;
    box-sizing: border-box;
    padding: 0 20px;
    min-width: 900px;
    flex: 1;
  }
}

.add_footer {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  margin: 0;
  box-sizing: border-box;
  padding: 10px 50px 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #FFFFFF;
  border-top: 1px solid #E9E9E9;
  z-index: 50;
}

.add_body_wrap {
  padding: 12px;
  height: 100%;
  min-height: calc(100vh - 108px);
  min-width: 1000px;

  .add_body {
    width: 100%;
    min-width: 1000px;
    background-color: #FFFFFF;
    padding-top: 10px;
    min-height: calc(100vh - 128px);

    .form_wrap {
      width: 740px;
      margin: 0 auto;
      padding-top: 10px;
      border-bottom: 1px solid #E9E9E9;

      .title {
        width: 100%;
        height: 38px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
        border-bottom: 1px solid #E9E9E9;
        margin: 20px 0 40px 0;
      }
    }
  }
}

@primary-color: #1890ff;