@font-face {
  font-family: "iconfont"; /* Project id 2255803 */
  src: url('iconfont.woff2?t=1642495569304') format('woff2'),
       url('iconfont.woff?t=1642495569304') format('woff'),
       url('iconfont.ttf?t=1642495569304') format('truetype');
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.iconshezhi:before {
  content: "\e64b";
}

.icon203yonghu_yonghu4:before {
  content: "\e63d";
}

.icondingdan:before {
  content: "\e667";
}

.iconzhexiantu:before {
  content: "\ec66";
}

.iconshouye:before {
  content: "\e8b9";
}

.iconshangpin:before {
  content: "\e683";
}

