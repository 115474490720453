
.ant-form-item-explain {
  min-height: 0 !important;
}

.list_add_body_wrap {
  padding: 20px;
  min-height: calc(100vh - 108px);

  .list_add_body {
    width: 100%;
    height: 100%;
    background-color: #FFFFFF;
    padding-top: 10px;
    min-height: calc(100vh - 128px);
    padding-bottom: 100px;

    .step_wrap {
      width: 80%;
      margin: 10px auto;
      padding: 10px 0;
    }

    .form_wrap {
      width: 740px;
      margin: 0 auto;
      padding-top: 10px;
      border-bottom: 1px solid #E9E9E9;

      .info_title {
        width: 100%;
        height: 38px;
        font-size: 16px;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.85);
        border-bottom: 1px solid #E9E9E9;
        margin: 20px 0 0 0;
      }

      .add_specification {
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin-top: 14px;
        height: 32px;

        .add_text {
          color: #1890FF;
          cursor: pointer;
          font-size: 14px;
        }

        .add_tips {
          margin-left: 16px;
          font-size: 12px;
          color: rgba(0, 0, 0, 0.45);
        }
      }

      .info_line {
        display: flex;
        align-items: flex-start;
        height: max-content;
        margin: 5px 0;
        flex-wrap: wrap;

        > span {
          color: #f81d22;
        }
      }
    }

    .attributes_block {
      width: 100%;
      height: max-content;
      padding: 10px;
      box-shadow: 0 0 6px rgba(0, 0, 0, .1);
      margin: 8px 0;
    }
  }
}

.bf-controlbar {
  border: 1px solid #e9e9e9;
  border-bottom: none;
}


@primary-color: #1890ff;