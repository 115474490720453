.order_item_wrap {
  width: 100%;
  min-width: 1050px;
  overflow-x: scroll;
  background-color: #ffffff;
  margin-bottom: 30px;

  .header {
    padding: 8px 16px;
    background-color: #FAFAFA;
    display: flex;

    .info {
      flex: 2;
      display: flex;

      .item {
        flex: 1;
        color: rgba(0, 0, 0, 0.85);
        font-size: 14px;
      }
    }

    .action {
      flex: 1;
      text-align: end;
      font-size: 14px;
      line-height: 22px;
      color: #1890FF;
      cursor: pointer;

      span {
        margin-right: 14px;
      }
    }
  }

  .body {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    border: 1px solid #E8E8E8;

    .commodity {
      flex: 2;
      border-right: 1px solid #E8E8E8;

      .detail {
        width: 100%;
        height: 88px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.65);
        border-bottom: 1px solid #E8E8E8;

        .name {
          flex: 1;
          color: rgba(0, 0, 0, 0.45);

          div:first-of-type {
            color: rgba(0, 0, 0, 0.65);
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            min-width: 300px;
          }
        }

        .text {
          width: 100px;
          text-align: center;
        }

        .picture {
          width: 48px;
          height: 48px;
          margin: 0 18px 0 24px;
        }
      }
      .detail:last-of-type{
        border-bottom: 0;
      }
    }

    .address_container {
      width: 250px;
      padding: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-right: 1px solid #E8E8E8;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.65);

      .address {

      }

      .pay_info {
        text-align: right;
      }
    }
    .address_container:last-of-type{
      border-right: 0;
    }
  }
}

@primary-color: #1890ff;