.hot_words_container {
  width: 100%;
  height: 400px;
  background-color: #FFFFFF;
  border-radius: 2px;

  .tab_line {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E8E8E8;
    height: 50px;
    justify-content: space-between;
    margin-bottom: 8px;

    .title {
      font-size: 13px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      align-items: baseline;

      span {
        font-size: 16px;
        margin-right: 24px;
      }
    }
  }
  .table_box{
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    height: 350px;
  }
}

@primary-color: #1890ff;