
.order_wrap {
  padding-top: 60px!important;
  .order_row{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-bottom: 20px;
    .before{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 30%;
      margin-right: 10px;
    }

    .after{
      display: flex;
      align-items: center;
      width: 40%;
      margin-left: 10px;
    }
  }
}

@primary-color: #1890ff;