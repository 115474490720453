.statistic_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: max-content;
  box-sizing: border-box;
  padding: 0 24px;
  background-color: #FFFFFF;
  border-radius: 2px;

  .title {
    font-size: 14px;
    line-height: 56px;
    color: rgba(0, 0, 0, 0.45);
  }

  .main_statistics {
    width: 100%;
    height: 70px;
    background-size: 100% 100%;
    font-size: 30px;
    line-height: 30px;
    color: rgba(0, 0, 0, 0.85);
  }

  .bottom_line {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    border-top: 1px solid #E8E8E8;
    margin-top: 20px;
  }
  .bottom_line span{
    font-size: 14px;
    line-height: 16px;
    color: rgba(0, 0, 0, 0.65);
  }
}

@primary-color: #1890ff;