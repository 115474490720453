.container{
  background-color: #f2f2f2;
  padding:10px;
  .header{
    background-color: #ffffff;
    padding: 16px 20px;
    min-height: 300px;
    width: 100%;
    .row{
      margin-top:26px;
      font-size:16px;
    }
    .tag{
      width: 100%;
      margin-top: 4px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f2f2f2;
      border-radius: 20px;
      font-size: 14px;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 6px;
    }
    .product_wrap{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 10px;
      .tag{
        width: 49%;
      }
    }
  }
  .footer{
    margin-top: 16px;
    background-color: #ffffff;
    padding: 16px 20px;
    min-height: 400px;
    width: 100%;
    .row{
      margin-top: 16px;
    }
  }

}

@primary-color: #1890ff;