.channel_ratio_container {
  width: 100%;
  height: 480px;
  background-color: #FFFFFF;
  border-radius: 2px;

  .tab_line {
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E8E8E8;
    height: 50px;
    justify-content: space-between;

    .title {
      font-size: 13px;
      line-height: 24px;
      color: rgba(0, 0, 0, 0.85);
      display: flex;
      align-items: baseline;

      span {
        font-size: 16px;
        margin-right: 24px;
      }
    }
  }

  .chart_box {
    width: 100%;
    box-sizing: border-box;
    padding-right: 24px;
    height: 320px;
    margin-top: 50px;
    display: flex;
    align-items: center;

    .chart {
      width: 50%;
      height: 100%;
      margin: 0 5px;
    }

    .legend {
      flex: 1;
      height: max-content;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .legend_line {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.65);
        margin: 10px 0;

        div:first-of-type {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 8px;
        }

        span {
          width: 50px;
        }
      }
    }
  }
}

@primary-color: #1890ff;