.table_head{
  width: 100%;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .search{
    height: 40px;
    display: block;
    max-width: 552px;
  }
}

@primary-color: #1890ff;