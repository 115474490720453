.contain_wrap {
  height: 100%;
  width: 100%;
  background-color: #fff;

  .pact_wrap {
    width: 60%;
    min-width: 800px;
    margin: 0 auto;

    .tab {
      padding: 20px 0;
    }

    .footer {
      margin-top: 20px;

    }
  }
}

@primary-color: #1890ff;