.setting_label{
  margin: 8px 0;
  > span{
    color: #f81d22;
  }
}
.setting_logo ,.setting_qrcode{
  display: flex;
  background-color: #f2f2f2;
  padding: 16px;
  .setting_logo_upload, .setting_qrcode_upload{
    width: 60%;
    padding-right: 26px;
    border-right: 1px solid #d7d7d7;
  }
  .setting_logo_demo, .setting_qrcode_demo{
    width: 40%;
    padding: 0 26px;
    .demo_title{
      padding: 8px 0;
    }
    .pic{
      width: 100%;
    }
  }
  .setting_address_wrap{
    background-color: #f2f2f2;
    padding: 16px;
  }
}

.cer_wrap{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

@primary-color: #1890ff;